<template>
  <div class="d-inline-flex">
    <h1 class="primary--text text-h5 text-sm-h4 text-md-h3 uderlined pb-2 mb-4 text-center">
      <slot />
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Title'
}
</script>

<style scoped>
.uderlined::after {
  content: ' ';
  display: block;
  width: 50%;
  height: 0.08em;
  margin: 10px auto auto auto;
  background-color: var(--v-primary-base);
}
</style>
